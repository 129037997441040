module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-google-gtag@5.14.0_gatsby@5.14.0_babel-eslint@10.1.0_eslint@9.16.0__encoding@0._pgxskbrzs6i3uryyr2i4h6k25m/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-04XE89SE0Y"],"gtagConfig":{}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.0_babel-eslint@10.1.0_eslint@9.16.0__encoding@0.1.1_4efdjctdyqrqcmq2g35slinfiu/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4ecaf268d9624ac8fea44a93b4fabcee"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-react-i18next@3.0.1_gatsby@5.14.0_babel-eslint@10.1.0_eslint@9.16.0__encoding@0_27iaidxp6euqykawth3bqrhkiy/node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["ja","en"],"defaultLanguage":"ja","fallbackLanguage":"en","siteUrl":"https://font.sakamata.ch","trailingSlash":"always","redirect":false,"generateDefaultLanguagePage":true,"i18nextOptions":{"keySeparator":false,"nsSeparator":false}},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.0_babel-eslint@10.1.0_eslint@9.16.0__encoding@0.1.13_react-dom@18.3.1_react@18.3._u3qgt5hn4synpejrjxga34nq5e/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
